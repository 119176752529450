import type {
  ContractFull,
  Endorsement,
  EndorsementFile,
  PolicyType,
  Ticket,
} from ".";
import type { InternalQuotation } from "./quotation";

export enum HiringStatus {
  ERROR = "error",
  EXEMPT_CONTRACT = "exempt_contract",
  FINISHED = "finished",
  PENDING = "pending",
  DONE = "done",
  ANALYSIS = "analysis",
  EMISSION = "emission",
  LEGACY_ERROR = "legacy_error",
  RENEWAL = "renewal",
  REFUSED = "refused",
  // Criado no front
  EMPTY = "empty",
}

export interface ContractHiringSimple {
  // ID do contract hiring
  id: number;
  // ID do contrato
  contract_id: number;
  // ID do endosso
  endorsement_id: number | null;
  // Status da contratação
  status: HiringStatus;
  // Tipo da apólice
  policy_type: PolicyType;
  // Ativo SIM ou NÃO
  active: boolean;
  // Datas
  created_at: string;
  updated_at: string;
}

export interface ContractHiring extends ContractHiringSimple {
  // Cotação
  contract_quote: InternalQuotation | null;
  // Endosso
  endorsement: Endorsement | null;
  // Contrato (opctional)
  contract?: ContractFull;
}

export interface HiringHistory {
  contract_hiring_id: number;
  policy_type: PolicyType;
  origin: "internal" | "external";
  contract_hiring_status: HiringStatus;
  premium: "" | number;
  endorsement_start_date: string;
  endorsement_end_date: string;
  endorsement_created_at: string;
  endorsement_number: string;
  endorsement_files: EndorsementFile[];
  payments: Ticket[];
}
