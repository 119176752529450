import { AxiosError } from "axios";
import { differenceInDays } from "date-fns";
import { useRoute } from "vue-router";
import { contractApi } from "~/api/contract";
import { disconnectStatus } from "~/plugins/auth";
import { type ContractFull, type HiringHistory } from "~/types";
import { useRCGStore, useRCPStore } from "..";
import type { PolicyExemptionFormFields } from "~/components/molecules/contract/exemption/form.vue";
import { hiringApi } from "~/api";

export const useContractStore = defineStore("contract-store", () => {
  const route = useRoute();
  const queryClient = useQueryClient();

  // Contrato
  const contract = ref<ContractFull>();
  const contractId = ref<string>();
  // Histórico de endossos
  const hiringHistory = ref<HiringHistory[]>([]);
  const isLoadingHistory = ref(false);
  // Apólice rcp
  const rcpStore = useRCPStore();
  // Apólice rcg
  const rcgStore = useRCGStore();

  const isLoading = ref(true);

  // Feedback para o caso de contrato inválido
  function showInvalidFeedback() {
    isLoading.value = false;
    navigateTo("/contratos");
    useNuxtApp().$toast("Contrato não encontrado", {
      type: "error",
      delay: 400,
    });
  }

  // Bate na API para buscar informações do contrato
  async function loadContract() {
    try {
      const id = route.params.id;
      if (!id || typeof id !== "string") {
        return showInvalidFeedback();
      }
      contractId.value = id;

      isLoading.value = true;

      const response = await contractApi.get(id, useNuxtApp().$auth.role.value);
      if (!response) {
        isLoading.value = false;
        return;
      }
      const contractData = response.data;
      if (!contractData) {
        return showInvalidFeedback();
      }
      contract.value = contractData;

      // Carrega o histórico de endossos
      await loadHiringHistory(id, contractData);

      const isExpiredContract =
        differenceInDays(
          parseDate(contractData.contract_end_date, "yyyy-MM-dd"),
          new Date()
        ) < 0;
      const isExemptContract = contractData.exempt_contract;

      // APÓLICE GERAL
      if (contractData.geral) {
        // Se for um contrato isento, carrega a apólice como isenta
        if (isExemptContract) {
          rcgStore.loadExemptPolicy();
        } else if (isExpiredContract) {
          // Carrega uma tela vazia quando o contrato for expirado
          rcgStore.loadPolicyForExpiredContract();
        } else {
          // Caso passe das validações acima, carrega o hiring
          await rcgStore.loadHiring(contractData.id);
        }
      }
      // APÓLICE PROFISSIONAL
      if (contractData.profissional) {
        // Se for uma empresa ou um contrato isento, carrega a apólice como isenta
        if (isExemptContract) {
          rcpStore.loadExemptPolicy();
        } else if (isExpiredContract) {
          // Carrega uma tela vazia quando o contrato for expirado
          rcpStore.loadPolicyForExpiredContract();
        } else {
          // Caso passe das validações acima, carrega o hiring
          await rcpStore.loadHiring(contractData.id);
        }
      }

      isLoading.value = false;
    } catch (error) {
      const errorStatus = (error as AxiosError).response?.status;
      if (errorStatus && disconnectStatus.includes(errorStatus)) {
        isLoading.value = false;
        return;
      }
      return showInvalidFeedback();
    }
  }

  async function loadHiringHistory(
    _contractId?: string,
    _contract?: ContractFull
  ) {
    try {
      const __contractId = _contractId || contractId.value;
      if (!__contractId) return;
      isLoadingHistory.value = true;
      const history = await hiringApi.getHiringHistory(
        __contractId,
        useNuxtApp().$auth.role.value
      );
      if (history) {
        hiringHistory.value = history;
      }
      isLoadingHistory.value = false;
    } catch (error) {
      useNuxtApp().$error({
        error,
        message: "Erro ao carregar histórico do contrato!",
        extra: {
          contract: contract.value,
        },
      });
      isLoadingHistory.value = false;
      throw error;
    }
  }

  const isExempting = ref(false);
  // Isenta o contrato
  async function exemptContract(data: PolicyExemptionFormFields) {
    try {
      if (!contract.value) return;
      isExempting.value = true;
      const response = await contractApi.exemptContract(
        contract.value.id,
        data
      );
      // Isenta as duas apólices
      if (contract.value.geral) rcgStore.loadExemptPolicy();
      if (contract.value.profissional) rcpStore.loadExemptPolicy();

      contract.value = {
        ...contract.value,
        ...response.data,
        exempt_contract: true,
      };
      queryClient.invalidateQueries();
      useNuxtApp().$toast("Contrato isento com sucesso!", {
        type: "success",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) {
        isExempting.value = false;
        throw error;
      }
      useNuxtApp().$error({
        error,
        message: "Erro ao isentar contrato!",
        extra: {
          contract: contract.value,
          data,
        },
      });
      isExempting.value = false;
      throw error;
    } finally {
      isExempting.value = false;
    }
  }

  function clearContract() {
    contract.value = undefined;
    rcgStore.clearPolicy();
    rcpStore.clearPolicy();
  }

  return {
    isLoading,
    contract,
    contractId,
    loadContract,
    clearContract,
    isExempting,
    exemptContract,
    hiringHistory,
    loadHiringHistory,
    isLoadingHistory,
  };
});
