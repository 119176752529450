import type { Endorsement } from ".";
import type { InternalQuotation } from "./quotation";

export enum InternalProposalStatus {
  APPROVED = "approved",
  REFUSED = "refused",
}

export interface InternalProposalFile {
  filename: string;
  created_at: string;
  proposal_file_id: string;
}

export interface InternalProposal {
  id: number;
  contract_quote_id: number;
  to_send: boolean;
  sent: boolean;
  start_date: string;
  end_date: string;
  proposal_status: InternalProposalStatus;
  proposal_status_description: string;
  endorsement_number: null | number | string;
  created_at: string;
  updated_at: string;
  proposal_files?: [InternalProposalFile];
  endorsement: null | Endorsement;
}

export interface InternalProposalListItem {
  id: number;
  contract_quote_id: number;
  to_send: boolean;
  sent: boolean;
  start_date: string;
  end_date: string;
  proposal_status: string;
  proposal_status_description: string;
  endorsement_number: null | string;
  created_at: string;
  updated_at: string;
  proposal_files: [InternalProposalFile];
  contract_quote: InternalQuotation;
  endorsement: Endorsement;
  editable?: boolean;
  block_edit_reason?: string;
}
export interface MinifiedProposalListItem {
  id: number;
  // Valor do prêmio líquido
  value?: number;
  // Valor do IOF
  iof?: number;
  // Valor do prêmio Total
  premium_value?: number;
  // Contrato
  contract?: string;
  // CNPJ
  cnpj?: string;
  // Número do endosso
  endorsement_number?: string | null | number;
  // N° da Proposta
  document_number?: string | number;
  // Status da proposta
  status?: InternalProposalStatus;
  // Data de início da vigência
  start_date?: string;
  // Data do fim da vigência
  end_date?: string;
  // Data de criação
  created_at?: string;
  // Arquivo de download da proposta
  proposal_files?: [InternalProposalFile];
  // Id da cotação
  contract_quote_id?: number;
  // É editável e recusável
  editable?: boolean;
  // Motivo de não ser editavel ou recusavel
  block_edit_reason?: string;
}

export interface UpdateInternalProposal {
  contract_quote: {
    value: string;
    premium_value: string;
    iof: string;
    start_date: string;
    end_date: string;
  };
}
