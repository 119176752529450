import * as Sentry from "@sentry/vue";
import { type AxiosError } from "axios";
import "vue3-toastify/dist/index.css";
import { disconnectStatus } from "./auth";

const sentryIgnoreErrors = [
  "ResizeObserver loop limit exceeded",
  "UnhandledRejection",
  "Route did not complete loading",
  "Abort fetching component for route",
  "Invariant: attempted to hard navigate to the same URL",
  "Unexpected token",
  "Non-Error promise rejection captured with keys",
  "A client-side exception has occurred",
  "is not a function",
  "Abort fetching component",
  "Tried to show a dialog on top of another dialog.",
  "Failed to read the",
  "Failed to load script",
  "Java exception was raised during method invocation",
  "Loading chunk",
  "is not defined",
];

export default defineNuxtPlugin({
  name: "sentry",
  setup: (nuxtApp) => {
    const router = useRouter();

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: useEnv("SENTRY_DSN"),
      environment: useEnv("AMBIENT"),
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maxReplayDuration:
            useEnv("AMBIENT") === "production" ? 1000 * 60 * 8 : 0, // 8 minutos
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: useEnv("AMBIENT") === "production" ? 0.1 : 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: useEnv("AMBIENT") === "production" ? 0.7 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      ignoreErrors: sentryIgnoreErrors,
    });

    const reportToSentry = (error: Error, extra?: { [key: string]: any }) => {
      const user = useNuxtApp().$auth?.user;
      Sentry.captureException(error, {
        extra: {
          ...(extra || {}),
          ...(user.value
            ? {
                user_name: user.value?.name,
                user_email: user.value?.email,
                user_role: user.value?.role,
                user_cnpj: user.value?.cnpj,
              }
            : {}),
        },
      });
    };

    function handleError({
      error,
      message,
      extra,
      showToast = true,
    }: {
      error: unknown;
      message: string;
      extra?: { [key: string]: any };
      showToast?: boolean;
    }) {
      const axiosError = error as AxiosError;
      const ambient = useEnv("AMBIENT") || "";
      const flag =
        {
          development: "[DEV] ",
          staging: "[STG] ",
          production: "[PROD] ",
        }[ambient] || undefined;
      if (showToast) {
        useNuxtApp().$toast(message, {
          type: "error",
          autoClose: 3500,
        });
      }
      const errorStatus = axiosError?.response?.status;
      if (errorStatus && disconnectStatus.includes(errorStatus)) return;

      let errorResponse = undefined;
      if (axiosError?.response?.data) {
        errorResponse = axiosError?.response?.data;
      }

      const errorInstance = new Error(`${flag || ""}${message}`, {
        cause: error,
      });
      reportToSentry(errorInstance, {
        ...extra,
        error_log: JSON.stringify(errorResponse),
        error_status: errorStatus,
      });
    }

    return {
      provide: { sentry: { reportToSentry }, error: handleError },
    };
  },
});
